*, *::before, *::after {
  box-sizing: border-box;
}

* {
  margin: 0;
  padding: 0;
}

ul[role='list'], ol[role='list'] {
  list-style: none;
}

html:focus-within {
  scroll-behavior: smooth;
}

a:not([class]) {
  text-decoration-skip-ink: auto;
}

body, html{
  height: 100%;
  scroll-behavior: smooth;
  font-family: SourceSans3, sans-serif;
}

#root {
  height: 100%;
}
