@font-face {
  font-family: 'SourceSans3';
  src: url("./SourceSans3-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SourceSans3';
  src: url("./SourceSans3-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
